import React, { useEffect } from "react";

import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";

import { registerBootlegVH } from "../utils/events";
import { QuizPage } from "../components/Pages/Quiz";

import { main } from "../styles/pages/Home.module.scss";

const QuizRootPage: React.FC = () => {
  useEffect(() => {
    registerBootlegVH();
  }, []);

  return (
    <Layout className={main} exhibition>
      <SEO title="Quiz" />

      <QuizPage exhibition />
    </Layout>
  );
};

export default QuizRootPage;
